import React from "react";
import "./teamPage.css";
import HelmetWrapper from "../../HelmetWrapper";
import { FaLinkedinIn } from "react-icons/fa";

// Importing Image of Team Members
import Vijayasena from "../../Assets/Team/VijayaSena.jpeg";
import SSN_Raju from "../../Assets/Team/RajSir.JPG";
import Yadav from "../../Assets/Team/yadhavSir.JPG";
import Ram from "../../Assets/Team/ram.JPG";
import Nageswara from "../../Assets/Team/Nagesh.jpeg";
import Srinivas from "../../Assets/Team/Srinivas.JPG";
import Jeevan from "../../Assets/Team/Jeevan.jpg";
import Theressa from "../../Assets/Team/teressa.JPG";
import Durga from "../../Assets/Team/Durga.JPG";
import Tejaswi from "../../Assets/Team/Tejaswi.jpg";
import Mani from "../../Assets/Team/Mani.jpg";

function TeamPage() {
  const Data = [
    {
      Name: "Sagi Satyanarayana Raju",
      Designation: "Head - Operations",
      Linkedin: "https://www.linkedin.com/in/drssnraju/",
      Image: SSN_Raju,
    },
    {
      Name: "Yadava Reddy",
      Designation: "Admin Manager",
      Linkedin: "https://www.linkedin.com/in/yadava-reddy-g-849406a6/",
      Image: Yadav,
    },
    {
      Name: "Ram Prasad Maddala",
      Designation: "Study Abroad Counselor",
      Linkedin:
        "https://www.linkedin.com/in/ram-prasad-m-39b61518?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      Image: Ram,
    },

    {
      Name: "VS Nagesh",
      Designation: "Business Head",
      Linkedin: "https://www.linkedin.com/in/vs-nagesh-7705b3313",
      Image: Nageswara,
    },
    {
      Name: "Srinivasa Reddy",
      Designation: "Business Development Manager",
      Linkedin: "https://www.linkedin.com/in/srinivasa-reddy-d/",
      Image: Srinivas,
    },
    {
      Name: "Gnana Theressa",
      Designation: "HR Executive",
      Linkedin: "https://www.linkedin.com/in/t-confluence-0b2512276/",
      Image: Theressa,
    },
    {
      Name: "Jeevan kumar",
      Designation: "Counselor & Marketing",
      Linkedin:
        "https://www.linkedin.com/in/jeevan-education-counselor-195ba0301/",
      Image: Jeevan,
    },
    {
      Name: "Tejaswi Ravuri",
      Designation: "Full Stack Developer",
      Linkedin: "https://www.linkedin.com/in/tejaswi-ravuri-26a2b9290",
      Image: Tejaswi,
    },
    {
      Name: "Durga Sai Gogi",
      Designation: "Full stack developer",
      Linkedin: "https://www.linkedin.com/in/durga-sai-gogi-6960b8214/",
      Image: Durga,
    },
    {
      Name: "Mani Preeth",
      Designation: "Fullstack Developer",
      Linkedin: "https://www.linkedin.com/in/manipreeth-bolusani",
      Image: Mani,
    },
  ];

  return (
    <div className="bg-white">
      <HelmetWrapper
        title="Our Team - Confluence Educational Services"
        description="At Confluence Educational Services, we offer a wide range of services
          designed to cater to the diverse needs of our students. From study
          abroad consulting to university admissions assistance, we provide
          comprehensive support at every step of the journey."
        canonicalUrl="https://www.confluenceedu.com/Team"
      />

      <h1 className="text-center mb-2">Our Team</h1>

      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center mb-4">
        <img src={Vijayasena} alt="Confluence ceo" className="rounded-3 m-4" />
        <div className="text-center text-md-start">
          <h2 className="ceoName">Solipuram Vijaya Sena Reddy</h2>
          <h4 className=" fw-medium text-secondary">Founder & CEO</h4>
          <p className="LinkedIn mb-0 ">
            <a
              href="https://www.linkedin.com/in/svs-reddy-5566982aa"
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none fw-medium d-flex align-items-center justify-content-center justify-content-md-start"
            >
              <span>LinkedIn&nbsp;</span>
              <FaLinkedinIn />
            </a>
          </p>
        </div>
      </div>

      <div className="d-flex flex-wrap justify-content-center teamPage mx-auto">
        {Data.map((value, index) => {
          return (
            <div className="m-3 m-md-5 p-2 ">
              <div className="d-flex justify-content-center">
                <img
                  src={value.Image}
                  alt={value.Name}
                  className="teamImage mb-2"
                />
              </div>
              <p className="mb-0 text-center">
                <b>{value.Name}</b>
              </p>
              <p className="mb-1 text-center fw-medium text-secondary">
                {value.Designation}
              </p>
              {value.Linkedin ? (
                <p className="LinkedIn text-center mb-0">
                  <a
                    href={value.Linkedin}
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none fw-medium d-flex align-items-center justify-content-center"
                  >
                    <span>LinkedIn&nbsp;</span>
                    <FaLinkedinIn />
                  </a>
                </p>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TeamPage;
