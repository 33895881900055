import { NavLink } from "react-router-dom";
import "./navbar.css";
export function DropdownMenu({ handleDropdownClick }) {
  return (
    <nav className="testprep-links">
      <NavLink to="/ielts" onClick={handleDropdownClick}>
        IELTS
      </NavLink>
      <NavLink to="/toefl" onClick={handleDropdownClick}>
        TOEFL
      </NavLink>
      <NavLink to="/gre" onClick={handleDropdownClick}>
        GRE
      </NavLink>
      <NavLink to="/duolingo" onClick={handleDropdownClick}>
        Duolingo
      </NavLink>
      <NavLink to="/sat" onClick={handleDropdownClick}>
        SAT
      </NavLink>
      <NavLink to="/pte" onClick={handleDropdownClick}>
        PTE
      </NavLink>
      <NavLink to="/gmat" onClick={handleDropdownClick}>
        GMAT
      </NavLink>
      <NavLink to="/german" onClick={handleDropdownClick}>
        German
      </NavLink>
      <NavLink to="/french" onClick={handleDropdownClick}>
        French
      </NavLink>
    </nav>
  );
}
