import React from "react";
import GmatDemo from "../../Assets/GMAT.jpeg";
import GmatPaid from "../../Assets/GMAT_Paid.jpeg";

import TestBanner from "./TestBanner";
import "./Ielts/ielts-images.css";

function Gmat() {
  const GmatImgs = [
    {
      imgSrc: GmatDemo,
      alt: "Gmat free demo",
    },
    {
      imgSrc: GmatPaid,
      alt: "Gmat Paid",
    },
  ];
  return (
    <div>
      <TestBanner GMAT={"GMAT"} />
      <div>
        {GmatImgs.map((Img) => (
          <a href="https://testprep.confluenceedu.com/">
            <img
              src={Img.imgSrc}
              alt={Img.alt}
              className="ieltsimg1 mb-2"
            ></img>
          </a>
        ))}
      </div>
    </div>
  );
}

export default Gmat;
